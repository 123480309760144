<template>
  <div id="app">
    <AppHeaderVue />
    <router-view />
    <ScrollTopButtonVue />
    <AppFooterVue />
  </div>
</template>

<script>
import AppHeaderVue from "./components/AppHeader.vue";
import AppFooterVue from "./components/AppFooter.vue";
import ScrollTopButtonVue from "./components/ScrollTopButton.vue";

export default {
  components: {
    AppHeaderVue,
    ScrollTopButtonVue,
    AppFooterVue,
  },
};
</script>
