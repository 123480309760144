<template>
  <section class="home-banner relative overflow-h">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12 d-flex flex-center">
          <div class="banner-content relative z-index-1">
            <h1 class="banner-title wow fadeInLeft">BringAuto Fleet</h1>
            <p class="wow fadeInLeft">
              Data flow and management for remote data acquiring, controling and processing
            </p>
            <p>
              Open-Source modular system that aims for easy integration and wide ranges of usages
              where data flow and control is needed!
            </p>
            <p><a href="https://github.com/bringauto">BringAuto GitHub</a></p>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12">
          <div class="">
            <img
              src="../assets/images/bringauto/ExternalClientServerCommunication.svg"
              alt="Banner Image"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MainText",
};
</script>
