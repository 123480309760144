<template>
  <footer id="footer" class="footer purple relative pt-100">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12">
          <div class="section-heading text-center">
            <h6 class="sub-title text-uppercase wow fadeInUp">CONTACT</h6>
            <h2 class="title wow fadeInUp">Get In Touch</h2>
            <p class="wow fadeInUp">
              Do you have a question? Feel free to contact us through
              <a href="https://github.com/bringauto/fleet/discussions">GitHub discussion</a> or by
              email.
            </p>
          </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12">
          <div class="section-heading text-center">
            <ul class="contact-link wow fadeInUp">
              <li>
                <span class="contact-icon"
                  ><img src="../assets/images/BringAutoFleet grafika_B-03.png" alt="Mail Icon"
                /></span>
                <a href="mailto:developers@bringauto.com">{{ mail }}</a>
              </li>
              <li>
                <span class="contact-icon"
                  ><img src="../assets/images/BringAutoFleet grafika_B-02.png" alt="Phone Icon"
                /></span>
                <a href="tel:+420774611994">{{ phone }}</a>
              </li>
              <li>
                <span class="contact-icon"
                  ><img src="../assets/images/BringAutoFleet grafika_B-01.png" alt="GitHub Icon"
                /></span>
                <a href="https://github.com/bringauto/fleet/discussion">Join us on GitHub</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright mt-100">
      <div class="container">
        <div class="ext-sm-start">
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-9 order-r-2">
              <p>
                &#9400; BringAuto<span> {{ currentYear }} </span>
              </p>
              <div class="right icons">
                <ul>
                  <li>
                    <a href="https://www.linkedin.com/company/bringauto/about/"
                      ><i class="fa fa-linkedin" aria-hidden="true"></i
                    ></a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/bringauto/"
                      ><i class="fa fa-instagram" aria-hidden="true"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return {
      mail: "fleet@bringauto.com",
      phone: "(+420) 774 611 994",
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style>
.icons {
  font-size: 175%;
}
.right {
  text-align: right;
  float: right;
}
.footer .container {
  position: relative;
}
.contact-link {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px -15px;
}
.contact-link li {
  width: 33.33%;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-link li .contact-icon {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.contact-link li .contact-icon img {
  position: relative;
  z-index: 1;
}
.contact-link li .contact-icon:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.contact-link li .contact-icon:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  border-radius: 100%;
}
.contact-link li a {
  padding-left: 10px;
}
form.get-touch {
  padding-top: 70px;
  max-width: 720px;
  margin: 0 auto;
}
.footer canvas {
  position: absolute;
  top: 0;
}
.copyright {
  border-top: 1px solid;
  padding: 38px 0px;
}
.copyright ul {
  text-align: right;
}
.copyright ul li {
  display: inline-block;
  padding: 0px 10px;
}
.copyright ul li:first-child {
  padding-left: 0;
}
.copyright ul li:last-child {
  padding-right: 0;
}

@media (max-width: 1250px) {
  .container {
    max-width: 96%;
  }
}

@media (max-width: 1199px) {
  .button {
    font-size: 16px;
    padding: 10px 25px;
  }
  .pt-100 {
    padding-top: 80px;
  }
  .pb-100 {
    padding-bottom: 80px;
  }
  .ptb-100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .mt-100 {
    margin-top: 80px;
  }
  .mb-100 {
    margin-bottom: 80px;
  }
  .mtb-100 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .page-banner {
    padding-top: 150px;
  }
  h1 {
    font-size: 44px;
    line-height: 58px;
  }
  h2 {
    font-size: 35px;
    line-height: 42px;
  }
  h3 {
    font-size: 25px;
    line-height: 32px;
  }
  h4 {
    font-size: 18px;
    line-height: 26px;
  }
  h5 {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 1024px) {
  .container {
    max-width: calc(100% - 60px);
  }
  .section-heading {
    padding-bottom: 30px;
  }
  .sub-title {
    font-size: 14px;
    padding-bottom: 10px;
  }
  .section-heading p {
    font-size: 14px;
    line-height: 23px;
  }
  .title {
    padding-bottom: 10px;
  }
  .pt-100 {
    padding-top: 60px;
  }
  .pb-100 {
    padding-bottom: 60px;
  }
  .ptb-100 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .mt-100 {
    margin-top: 60px;
  }
  .mb-100 {
    margin-bottom: 60px;
  }
  .mtb-100 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .page-banner {
    padding-top: 130px;
    padding-bottom: 70px;
  }
  h1 {
    font-size: 38px;
    line-height: 48px;
  }
  h2 {
    font-size: 32px;
    line-height: 38px;
  }
  h3 {
    font-size: 24px;
    line-height: 32px;
  }

  /*---------- FOOTER STYLE ------------*/

  .contact-link li a {
    width: 100%;
    margin-top: 10px;
  }
  .contact-link li {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
  form.get-touch {
    padding-top: 40px;
  }
}
@media (max-width: 991px) {
  .order-t-2 {
    order: 2;
  }
  .order-t-1 {
    order: 1;
  }
}

@media (max-width: 767px) {
  .container {
    max-width: 100%;
  }
  .button {
    font-size: 14px;
    padding: 8px 22px;
  }
  .owl-dots .owl-dot {
    width: 10px;
    height: 10px;
    margin: 0px 5px;
  }
  .owl-dots .owl-dot span {
    width: 4px;
    height: 4px;
  }
  .order-r-2 {
    order: 2;
  }
  .order-r-1 {
    order: 1;
  }
  .pt-100 {
    padding-top: 40px;
  }
  .pb-100 {
    padding-bottom: 40px;
  }
  .ptb-100 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .mt-100 {
    margin-top: 40px;
  }
  .mb-100 {
    margin-bottom: 40px;
  }
  .mtb-100 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .page-banner {
    padding-top: 100px;
    padding-bottom: 40px;
  }
  .breadcum li {
    padding: 0px 15px;
  }
  .modal-dialog {
    width: calc(100% - 30px);
    margin: 0 auto;
  }
  h1 {
    font-size: 30px;
    line-height: 38px;
  }
  h2 {
    font-size: 26px;
    line-height: 34px;
  }
  h3 {
    font-size: 20px;
    line-height: 26px;
  }
  h4 {
    font-size: 16px;
    line-height: 24px;
  }

  /*---------- FOOTER STYLE ------------*/

  .contact-link li {
    width: 100%;
    margin-bottom: 20px;
  }
  form.get-touch {
    padding-top: 10px;
  }
  .contact-link li a {
    padding-left: 0;
  }
  .form-group {
    margin-bottom: 15px;
  }
  .form-control {
    padding: 8px 15px;
  }
  .copyright {
    text-align: center;
  }
  .copyright ul {
    text-align: center;
    padding-bottom: 10px;
  }
}
</style>
