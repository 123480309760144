<template>
  <section id="bringauto-pi" class="bluish-purple relative overflow-h ptb-100">
    <div class="HomePageSection"></div>
    <div class="container">
      <div class="row d-flex flex-center relative z-index-1">
        <div class="col-xl-6 col-lg-6 col-md-12 wow fadeInLeft">
          <div class="token-details">
            <h2 class="token-title">BringAuto Pi</h2>
            <p>
              BringAuto Pi is a gateway computer module connecting all our cars to the internet!
            </p>
            <p>It serves as a gateway to connect BringAuto Robot to the Cloud!</p>
            <p>
              BringAuto Pi is a simple and reliable compute module case. As a main compute module
              the Raspberry Pi 4 is used as default.
            </p>
            <p>
              It can be used to house any Raspberry Pi compatible module or custom module of any
              needs and requirements!
            </p>
            <p>
              It can be bought at <a href="https://www.amazon.de/dp/B0C2WS3NQV">Amazon</a> or by
              direct sale contact us on
              <a href="mailto:weloverobots@bringauto.com">weloverobots@bringauto.com</a>
            </p>
            <p><a href="https://doc.bringauto.com/">Documentation</a></p>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 wow fadeInRight">
          <div class="token-graph text-center">
            <img src="../assets/images/bringauto/BringAutoPi.png" alt="Graph" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BringAutoPi",
};
</script>
