<template>
  <button v-show="isVisible" class="scroll-top-button" @click="scrollToTop">
    <img src="../assets/images/BringAutoFleet grafika-TOP.png" />
  </button>
</template>

<script>
export default {
  name: "ScrollTopButtonComponent",
  data: () => ({
    isVisible: false,
  }),

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    handleScroll() {
      this.isVisible = window.scrollY > 30;
    },
  },
};
</script>

<style>
.scroll-top-button {
  position: fixed;
  padding: 0;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  cursor: pointer;
  height: 105px;
  width: 105px;
  background-color: transparent;
}
</style>
