<template>
  <section id="fleet-management" class="purple relative overflow-h ptb-100">
    <div class="HomePageSection"></div>
    <div class="container">
      <div class="row d-flex flex-center relative z-index-1">
        <div class="col-xl-6 col-lg-6 col-md-12 wow fadeInLeft">
          <div class="token-details">
            <h2 class="token-title">Fleet Management</h2>
            <p>Simple and Open-Source Fleet management for not only autonomous fleets!</p>
            <p>
              <a href="https://github.com/bringauto/fleet">Fleet Management GitHub Repository</a>
            </p>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 wow fadeInRight">
          <div class="token-graph text-center">
            <img src="../assets/images/bringauto/FleetManagement_ScreenShot.png" alt="Graph" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FleetManagement",
};
</script>
