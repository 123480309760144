<template>
  <div class="home">
    <MainTextVue />
    <FleetManagement />
    <BringAutoPi />
  </div>
</template>

<script>
import MainTextVue from "../components/MainText.vue";
import FleetManagement from "../components/FleetManagement.vue";
import BringAutoPi from "../components/BringAutoPi.vue";

export default {
  name: "HomeView",
  components: {
    MainTextVue,
    FleetManagement,
    BringAutoPi,
  },
};
</script>
