<template>
  <header class="header" :class="{ sticky: stickyMenu }">
    <div class="container">
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-8 d-flex flex-center">
          <div class="logo">
            <router-link to="/">
              <img src="../assets/images/logo.png" alt="BringAuto Logo" />
            </router-link>
          </div>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-8 col-4">
          <div class="menu-toggle" :class="{ active: isOpened }" @click="toggleMenu()">
            <span></span>
          </div>
          <div class="main-menu" :class="{ isOpened }">
            <div class="nav-menu text-right">
              <ul>
                <template v-for="item in menuItems">
                  <DropdownMenu v-if="item.children" v-bind="item" :key="item.title" />
                  <MenuItem v-else v-bind="item" :key="item.title" />
                </template>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "MainHeader",
  components: {
    MenuItem: () => import("./MenuItem.vue"),
    DropdownMenu: () => import("./DropdownMenu.vue"),
  },
  data: () => ({
    menuItems: [
      {
        title: "Home",
        link: "/",
      },
      {
        title: "About Us",
        link: "https://bringauto.com/",
      },
      {
        title: "Products",
        children: [
          {
            title: "Fleet Management",
            link: "#fleet-management",
          },
          {
            title: "BringAuto Pi",
            link: "#bringauto-pi",
          },
        ],
      },
    ],
    isOpened: false,
    subMenuIsOpened: false,
    stickyMenu: false,
  }),

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.stickyMenu = window.scrollY > 0;
    },
    toggleMenu() {
      this.isOpened = !this.isOpened;
    },
    toggleSubMenu() {
      this.subMenuIsOpened = !this.subMenuIsOpened;
    },
  },
};
</script>

<style>
.isOpened {
  display: block !important;
}
.logo a:hover {
  opacity: 0.6;
}
.header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  transition: all 400ms ease;
}
.main-menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.nav-menu {
  width: calc(100% - 124px);
}
.nav-menu > ul {
  padding-right: 45px;
  line-height: 0;
  text-align: right;
}
.nav-menu > ul > li {
  display: inline-block;
  margin: 0px 25px;
  position: relative;
}
.nav-menu > ul > li:first-child {
  margin-left: 0;
}
.nav-menu > ul > li:last-child {
  margin-right: 0;
}
.nav-menu > ul > li > a {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 38px 0px;
  display: block;
  position: relative;
  overflow: hidden;
  line-height: 23px;
}
.nav-menu > ul > li > a:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  transition: all 400ms ease;
}
.sticky .nav-menu > ul > li > a {
  padding: 25px 0px;
}

@media (max-width: 1250px) {
  .container {
    max-width: 96%;
  }
}

@media (max-width: 1199px) {
  .button {
    font-size: 16px;
    padding: 10px 25px;
  }
  .pt-100 {
    padding-top: 80px;
  }
  .pb-100 {
    padding-bottom: 80px;
  }
  .ptb-100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .mt-100 {
    margin-top: 80px;
  }
  .mb-100 {
    margin-bottom: 80px;
  }
  .mtb-100 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .page-banner {
    padding-top: 150px;
  }
  h1 {
    font-size: 44px;
    line-height: 58px;
  }
  h2 {
    font-size: 35px;
    line-height: 42px;
  }
  h3 {
    font-size: 25px;
    line-height: 32px;
  }
  h4 {
    font-size: 18px;
    line-height: 26px;
  }
  h5 {
    font-size: 16px;
    line-height: 24px;
  }

  .nav-menu {
    width: calc(100% - 90px);
  }
  .nav-menu > ul > li > a {
    padding: 30px 0px;
  }
  .sticky .nav-menu > ul > li > a {
    padding: 20px 0px;
  }
}

@media (min-width: 1025px) {
  /*---------- HEADER STYLE ------------*/

  .nav-menu > ul > li.active > a:before,
  .nav-menu > ul > li:hover > a:before {
    left: 0;
  }

  /*---------- Drop Down ------------*/

  .nav-menu > ul ul {
    position: absolute;
    top: calc(100% + 20px);
    left: 0;
    min-width: 250px;
    padding: 15px 0px;
    text-align: left;
    border-radius: 5px;
    border-top-left-radius: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 400ms ease;
  }
  .sticky .nav-menu > ul ul {
    border-top-right-radius: 0;
  }
  .nav-menu > ul ul li a {
    display: block;
    padding: 8px 15px;
    position: relative;
    padding-left: 45px;
    line-height: 23px;
  }
  .nav-menu > ul ul li a:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 24px;
    width: 10px;
    height: 2px;
    transform: translateY(-50%);
    transition: all 400ms ease;
  }
  .nav-menu > ul ul:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 12px;
    position: absolute;
    top: -12px;
    left: 0px;
    transition: all 400ms ease;
  }
  .nav-menu > ul ul li.active a,
  .nav-menu > ul ul li:hover a {
    padding-left: 55px;
  }
  .nav-menu > ul ul li.active a:before,
  .nav-menu > ul ul li:hover a:before {
    width: 20px;
  }
  .nav-menu > ul li:hover ul {
    top: 100%;
    visibility: visible;
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .container {
    max-width: calc(100% - 60px);
  }
  .section-heading {
    padding-bottom: 30px;
  }
  .sub-title {
    font-size: 14px;
    padding-bottom: 10px;
  }
  .section-heading p {
    font-size: 14px;
    line-height: 23px;
  }
  .title {
    padding-bottom: 10px;
  }
  .pt-100 {
    padding-top: 60px;
  }
  .pb-100 {
    padding-bottom: 60px;
  }
  .ptb-100 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .mt-100 {
    margin-top: 60px;
  }
  .mb-100 {
    margin-bottom: 60px;
  }
  .mtb-100 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .page-banner {
    padding-top: 130px;
    padding-bottom: 70px;
  }
  h1 {
    font-size: 38px;
    line-height: 48px;
  }
  h2 {
    font-size: 32px;
    line-height: 38px;
  }
  h3 {
    font-size: 24px;
    line-height: 32px;
  }

  /*---------- HEADER STYLE ------------*/

  .header {
    padding: 15px 0px;
  }
  .logo img {
    max-width: 170px;
  }
  .main-menu {
    position: fixed;
    top: 59px;
    left: 0;
    width: 100%;
    height: calc(100vh - 59px);
    display: none;
    padding: 0px 45px;
    border-top: 1px solid;
    overflow: auto;
  }
  .nav-menu {
    width: 100%;
    text-align: left !important;
  }
  .nav-menu > ul {
    padding-right: 0;
    text-align: left;
    padding-top: 10px;
  }
  .nav-menu > ul > li {
    display: block;
    margin: 0;
  }
  .nav-menu > ul > li > a {
    padding: 15px 0px;
    font-size: 16px;
  }
  .sticky .nav-menu > ul > li > a,
  .nav-menu > ul > li > a {
    padding: 10px 0px;
  }
  .nav-menu > ul ul {
    display: none;
  }
  .login-box .button {
    padding: 10px 0px;
    background: transparent;
    font-weight: 500;
  }
  .nav-menu > ul ul {
    display: none;
    background: transparent;
    padding-left: 20px;
  }
  .nav-menu > ul ul li a {
    display: block;
    line-height: 22px;
    padding: 8px 0px;
    position: relative;
    padding-left: 15px;
    font-size: 16px;
  }
  .nav-menu > ul ul li a:before {
    content: "";
    width: 5px;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  .menu-toggle {
    width: 25px;
    height: 18px;
    position: absolute;
    top: 50%;
    right: 45px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
  }
  .menu-toggle span,
  .menu-toggle span:before,
  .menu-toggle span:after {
    width: 100%;
    height: 2px;
    display: block;
    transition: all 400ms ease;
  }
  .menu-toggle span:before,
  .menu-toggle span:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
  }
  .menu-toggle span:after {
    top: unset;
    bottom: 0;
    left: 0;
  }
  .menu-toggle.active span:before {
    transform: rotate(-45deg);
    top: 8px;
  }
  .menu-toggle.active span:after {
    transform: rotate(45deg);
    top: 8px;
  }
  .nav-menu > ul > li.menu-dropdwon > a:before,
  .nav-menu > ul > li.menu-dropdwon > a:after {
    content: "";
    position: absolute;
    border: 0;
    width: 14px;
    height: 2px;
    top: 50%;
    right: 0;
    transform: unset;
    left: unset;
    opacity: 1;
    visibility: visible;
    transform: translateY(-50%);
    transition: all 400ms ease;
  }
  .nav-menu > ul > li.menu-dropdwon > a:after {
    right: 6px;
    width: 2px;
    height: 14px;
  }
  .nav-menu > ul > li.menu-dropdwon > a.active:after {
    transform: translateY(-50%) rotate(90deg);
  }
}

@media (max-width: 991px) {
  .order-t-2 {
    order: 2;
  }
  .order-t-1 {
    order: 1;
  }
}

@media (max-width: 767px) {
  .container {
    max-width: 100%;
  }
  .button {
    font-size: 14px;
    padding: 8px 22px;
  }
  .owl-dots .owl-dot {
    width: 10px;
    height: 10px;
    margin: 0px 5px;
  }
  .owl-dots .owl-dot span {
    width: 4px;
    height: 4px;
  }
  .order-r-2 {
    order: 2;
  }
  .order-r-1 {
    order: 1;
  }
  .pt-100 {
    padding-top: 40px;
  }
  .pb-100 {
    padding-bottom: 40px;
  }
  .ptb-100 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .mt-100 {
    margin-top: 40px;
  }
  .mb-100 {
    margin-bottom: 40px;
  }
  .mtb-100 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .page-banner {
    padding-top: 100px;
    padding-bottom: 40px;
  }
  .breadcum li {
    padding: 0px 15px;
  }
  .modal-dialog {
    width: calc(100% - 30px);
    margin: 0 auto;
  }
  h1 {
    font-size: 30px;
    line-height: 38px;
  }
  h2 {
    font-size: 26px;
    line-height: 34px;
  }
  h3 {
    font-size: 20px;
    line-height: 26px;
  }
  h4 {
    font-size: 16px;
    line-height: 24px;
  }

  /*---------- HEADER STYLE ------------*/

  .header {
    width: 100%;
  }
  .main-menu {
    top: 58px;
    height: calc(100vh - 51px);
  }
  .menu-toggle {
    right: 15px;
  }
}
</style>
